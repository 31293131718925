import { ReactNode, memo, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHelpOutline, MdOutlineCancel } from 'react-icons/md'
import clsx from 'clsx'
import { animate, useMotionValue, useReducedMotion } from 'framer-motion'
import { useOnClickOutside, useResizeObserver } from 'usehooks-ts'
import Mixpanel from 'services/analytics/Mixpanel'
import Card from 'components/common/Card'
import styles from './ImpactDataCard.module.scss'

const AnimatedCounter = ({
  to,
  skipAnimation = false,
}: {
  to: number
  skipAnimation?: boolean
}) => {
  const ref = useRef<HTMLSpanElement>(null)
  const shouldReduceMotion = useReducedMotion()
  const count = useMotionValue(0)

  useLayoutEffect(() => {
    const element = ref.current

    if (!element) return

    element.textContent = String(0)

    if (shouldReduceMotion || skipAnimation) {
      const rounded = Math.round(to)
      element.textContent = rounded.toLocaleString('en-GB')
      return
    }

    const controls = animate(count, to, {
      duration: 0.6,
      onUpdate(value) {
        const rounded = Math.round(value)
        element.textContent = rounded.toLocaleString()
      },
    })

    return () => {
      controls.stop()
    }
  }, [ref, to])

  return <span ref={ref} />
}

type ImpactDataCardProps = {
  icon: ReactNode
  title: string
  value: string
  unit?: string
  overlayTitle: string
  overlayContent: string
  overlayLink?: string
  overlaySource?: string
  pdfDisplayMode?: boolean
}

const ImpactDataCard: React.FC<ImpactDataCardProps> = ({
  icon,
  title,
  value,
  overlayTitle,
  overlayContent,
  unit,
  overlayLink,
  overlaySource,
  pdfDisplayMode = false,
}) => {
  const { t } = useTranslation()
  const cardRef = useRef<HTMLDivElement>()
  const overlayRef = useRef<HTMLDivElement>()
  const [elementPosition, setElementPosition] = useState<any>()
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  useOnClickOutside(overlayRef, () => setIsOverlayVisible(false))

  const { width = 0 } = useResizeObserver({ ref: cardRef })
  useLayoutEffect(() => {
    const updatePosition = () => {
      setElementPosition(cardRef.current.getBoundingClientRect())
    }
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('scroll', updatePosition)
    }
  }, [])

  const handleHelpClick = () => {
    Mixpanel.track('PARTNER_IMPACT_DATA_CARD_HELP_ICON_CLICK')
    setIsOverlayVisible(true)
  }

  return (
    <>
      <div ref={cardRef}>
        <Card className={styles.wrapper}>
          <div className={styles.topSection}>
            <h3 className={clsx(styles.title, { [styles.pdfDisplayMode]: pdfDisplayMode })}>
              <span>{icon}</span>
              {title}
              {unit && <span className={styles.unit}>({unit})</span>}
            </h3>
            {!pdfDisplayMode && (
              <span className={styles.helpIcon}>
                <MdHelpOutline
                  onClick={handleHelpClick}
                  data-testid="help-icon"
                />
              </span>
            )}
          </div>
          <div
            className={styles.value}
            data-testid={`value-${title.replace(/\s/g, '')}`}
          >
            <AnimatedCounter
              to={Number(value)}
              skipAnimation={pdfDisplayMode}
            />
          </div>
        </Card>
      </div>

      {isOverlayVisible && (
        <div className={styles.overlayBackground}>
          <div ref={overlayRef}>
            <Card
              className={styles.overlay}
              style={{ width, marginLeft: elementPosition?.x, marginTop: elementPosition?.y }}
            >
              <div className={styles.topSection}>
                <h3 className={styles.overlayTitle}>{overlayTitle}</h3>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsOverlayVisible(false)}
                  type="button"
                >
                  <MdOutlineCancel data-testid="closeIcon" />
                </button>
              </div>
              <p className={styles.overlayContent}>{overlayContent}</p>
              {overlayLink && (
                <>
                  <p>{t('scenes.hub.impact-data.cards.source')}</p>
                  {overlaySource && <span>{overlaySource}</span>}
                  <a
                    href={overlayLink}
                    className={styles.overlayLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {overlayLink}
                  </a>
                </>
              )}
            </Card>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(ImpactDataCard)
