import { useEffect, useMemo, useState, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectedBusiness } from 'hooks'
import Button from 'components/common/Button'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Pill from 'components/common/Pill'
import SceneHeader from 'components/common/SceneHeader'
import { useStores } from 'hooks/api/hub'
import { useCollectionsForBusiness } from 'hooks/api/hub/'
import styles from './CollectionsScene.module.scss'

const AddCollectionModal = lazy(() => import('components/hub/Collections/AddCollectionModal'))

const CollectionsScene = () => {
  const { t } = useTranslation()
  const { stores, refetch: refetchStores } = useStores()
  const { selectedBusinessId } = useSelectedBusiness()
  const {
    data: collections = [],
    error,
    isLoading,
    refetch: refetchCollections,
  } = useCollectionsForBusiness(selectedBusinessId)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isMutationSuccess, setIsMutationSuccess] = useState(undefined)
  const selectedStores = useMemo(() => {
    return stores?.filter((store) => store.business_id === selectedBusinessId)
  }, [stores, selectedBusinessId, isMutationSuccess])

  useEffect(() => {
    if (isMutationSuccess) {
      refetchCollections()
      refetchStores()
    }
  }, [isMutationSuccess])

  return (
    <div>
      <SceneHeader title={t('scenes.hub.collections.upcoming')} />
      <Button
        onClick={() => {
          setIsModalOpen(true)
          setIsMutationSuccess(undefined)
        }}
        className={styles.scheduleButton}
      >
        {t('scenes.hub.collections.schedule')}
      </Button>
      {isMutationSuccess && <p className={styles.success}>{t('scenes.hub.collections.success')}</p>}
      {isMutationSuccess === false && (
        <p className={styles.error}>{t('scenes.hub.collections.error')}</p>
      )}

      <Suspense fallback={<LoadingSpinner />}>
        <AddCollectionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          stores={selectedStores}
          setIsMutationSuccess={setIsMutationSuccess}
          businessId={selectedBusinessId}
        />
      </Suspense>

      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>{t('common.error')}</div>
      ) : (
        <div className={styles.collectionTableWrapper}>
          <table className={styles.collectionTable}>
            <thead>
              <tr>
                <th>{t('scenes.hub.collections.date')}</th>
                <th>{t('scenes.hub.collections.time')}</th>
                <th>{t('scenes.hub.collections.location')}</th>
                <th>{t('scenes.hub.collections.status')}</th>
                <th>{t('scenes.hub.collections.slot_type')}</th>
              </tr>
            </thead>
            <tbody>
              {collections.map((collection, index) => {
                const isLastRow = index === collections.length - 1
                return (
                  <tr
                    key={index}
                    className={isLastRow ? styles.lastRow : ''}
                  >
                    <td>{new Date(collection.next_collection).toLocaleDateString()}</td>
                    <td>
                      {new Date(collection.next_collection).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td>{collection.location}</td>
                    <td>
                      {collection.state === 'assigned' || collection.state === 'active' ? (
                        <Pill
                          color="green"
                          size="small"
                        >
                          <Pill.Text>{t('scenes.hub.collections.volunteer_assigned')}</Pill.Text>
                        </Pill>
                      ) : (
                        <Pill
                          color="grey"
                          size="small"
                        >
                          <Pill.Text>{t('scenes.hub.collections.looking_for_volunteer')}</Pill.Text>
                        </Pill>
                      )}
                    </td>
                    <td>{collection.slot_type}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default CollectionsScene
