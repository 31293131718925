import i18next from 'i18next'
import { DateTime } from 'luxon'

/**
 * @param {string} date ISO Date to use for formatting
 * @param {string} timezone optional
 * @returns string like "Friday 15 Mar 2024"
 */
export const formatDateToDayOfWeekMonthAndTime = ({
  date,
  timezone,
}: {
  date: string
  timezone?: string
}) => {
  if (!date) {
    return null
  }

  if (timezone !== undefined) {
    return DateTime.fromISO(date, { zone: timezone, locale: i18next.language }).toFormat(
      'EEEE dd MMM yyyy',
    )
  } else {
    return DateTime.fromISO(date, { locale: i18next.language }).toFormat('EEEE dd MMM yyyy')
  }
}
