import { useQueryClient } from '@tanstack/react-query'

const useDownloadCsv = () => {
  const queryClient = useQueryClient()

  const downloadCsv: () => void = queryClient.getQueryData(['impactDataCsv']) ?? (() => {})

  return downloadCsv
}

export default useDownloadCsv
