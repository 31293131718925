import { Navigate, useLocation } from 'react-router-dom'
import { useSessionData } from 'hooks'
import { DASHBOARD_URL } from 'constants/routes'

const RequireNoAuthentication = ({ children }) => {
  const location = useLocation()
  const { isUserAuthenticated } = useSessionData()

  if (isUserAuthenticated) {
    return (
      <Navigate
        to={DASHBOARD_URL}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

export default RequireNoAuthentication
