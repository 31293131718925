import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const authenticateEmail = async (email) => {
  const response = await axios.post(`/api/v1/sessions/email`, {
    session: {
      email,
    },
  })
  return response.data
}

const useAuthenticateEmail = () => {
  const { mutate } = useMutation({
    mutationFn: authenticateEmail,
  })

  return {
    authenticateEmail: mutate,
  }
}

export default useAuthenticateEmail
