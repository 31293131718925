import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import clsx from 'clsx'
import { useSessionData } from 'hooks'
import Mixpanel from 'services/analytics/Mixpanel'
import Footer from 'components/layout/Hub/Footer'
import Sidebar from 'components/layout/Sidebar'
import useAffiliationInfo from 'hooks/useAffiliationInfo'
import useIsMobile from 'hooks/useIsMobile'
import Header from '../Header'
import styles from './AuthenticatedLayout.module.scss'

const AuthenticatedLayout = () => {
  const { t } = useTranslation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const isMobile = useIsMobile()
  const { isUserAuthenticated, session } = useSessionData()
  const affiliationInfo = useAffiliationInfo()

  const toggleSidebar = () => {
    if (isMobile === true) {
      setIsSidebarOpen((value) => !value)
    }
  }

  useEffect(() => {
    const identifyUser = () => {
      const identifier = `phub-${session.id}`
      Mixpanel.identify(identifier)
      Mixpanel.setContactId(session.id)
    }
    if (isUserAuthenticated) {
      identifyUser()
    }
  }, [isUserAuthenticated])

  useEffect(() => {
    if (affiliationInfo) {
      Mixpanel.setAffiliations(affiliationInfo)
    }
  }, [affiliationInfo])

  return (
    <>
      <div
        className={clsx({ [styles.overlay]: isSidebarOpen === true })}
        aria-label={t('layouts.authenticated.close_sidebar')}
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
        tabIndex={0}
        role="button"
      />
      <Header toggleSidebar={toggleSidebar} />
      <main className={styles.wrapper}>
        {isUserAuthenticated && (
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )}

        <div className={styles.sceneContentWrapper}>
          <div className={styles.sceneContent}>
            <Outlet />
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default AuthenticatedLayout
