import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum-slim'
import * as Sentry from '@sentry/react'
import Debug from 'helpers/debug'
import Env from 'helpers/environment'
import AppHub from './AppHub'

const debug = Debug('application:switch')

Sentry.init({
  enabled: !!Env.PUBLIC_SENTRY_DSN,
  dsn: Env.PUBLIC_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: Env.PUBLIC_SENTRY_SAMPLING_RATE ? Number(Env.PUBLIC_SENTRY_SAMPLING_RATE) : 1.0,
  environment: Env.PUBLIC_REACT_ENV,
})

if (Env.PUBLIC_DATADOG_APPLICATION_ID && Env.PUBLIC_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: Env.PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: Env.PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: Env.PUBLIC_DATADOG_SERVICE_PARTNERS,
    env: Env.PUBLIC_REACT_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
    telemetrySampleRate: 0,
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const containerHub = document.getElementById('root-hub')

  if (containerHub) {
    debug('Loading the Partner Hub...')
    const sessionData: SessionDataType = JSON.parse(containerHub.getAttribute('session-data'))
    const rootHub = createRoot(containerHub)
    rootHub.render(<AppHub session={sessionData} />)
  }
})
