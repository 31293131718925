import { useMemo } from 'react'
import { useSessionData, useSelectedBusiness } from 'hooks'

const useShouldShowPosterLink = () => {
  const { selectedBusinessId } = useSelectedBusiness()
  const { session } = useSessionData()

  const shouldAccessStorePoster = useMemo(() => {
    if (!session?.affiliations || !selectedBusinessId) {
      return false
    }

    const isBusinessAffiliation = session.affiliations.some(
      (item) => item.contactable_type === 'Business' && item.contactable_id === selectedBusinessId,
    )

    return isBusinessAffiliation
  }, [selectedBusinessId, session])

  return { shouldAccessStorePoster }
}

export default useShouldShowPosterLink
