import { Navigate } from 'react-router-dom'
import { DASHBOARD_URL } from 'constants/routes'
import StorePosterScene from 'scenes/hub/StorePosterScene'
import useShouldShowPosterLink from 'hooks/useShouldShowPosterLink'

const StorePosterRoute = () => {
  const { shouldAccessStorePoster } = useShouldShowPosterLink()
  return shouldAccessStorePoster ? <StorePosterScene /> : <Navigate to={DASHBOARD_URL} />
}

export default StorePosterRoute
