import { motion } from 'framer-motion'
import useIsMobile from 'hooks/useIsMobile'
import styles from './Sidebar.module.scss'
import SidebarContent from './SidebarContent/SidebarContent'
import { MOBILE_INITIAL, SIDEBAR_ANIMATION_CONFIG } from './animationConfig'

type SidebarType = {
  isSidebarOpen?: boolean
  toggleSidebar?: () => void
}
const Sidebar = ({ isSidebarOpen = false, toggleSidebar }: SidebarType) => {
  const isMobile = useIsMobile()

  return (
    <motion.div
      initial={isMobile === true && MOBILE_INITIAL}
      animate={isSidebarOpen === false && isMobile === true ? 'close' : 'open'}
      variants={SIDEBAR_ANIMATION_CONFIG.variants}
      transition={{ duration: SIDEBAR_ANIMATION_CONFIG.duration }}
      className={styles.sidebar}
      data-testid="sidebarAnimated"
      role="navigation"
    >
      <SidebarContent toggleSidebar={toggleSidebar} />
    </motion.div>
  )
}

export default Sidebar
