import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import LoginForm from 'components/hub/LoginForm'
import styles from './MagicLoginScene.module.scss'

const MagicLoginScene = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.magicLoginScene}>
      <h2 className={styles.title}>{t('scenes.hub.title')}</h2>
      <div className={clsx('card', styles.card)}>
        <div className="card-content">
          <div>
            <div className={styles.description}>
              <p>{t('scenes.hub.magic-login.description1')}</p>
              <p>{t('scenes.hub.magic-login.description2')}</p>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(MagicLoginScene)
