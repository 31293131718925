import { TargetAndTransition, Variant } from 'framer-motion'

type sidebarAnimationConfigType = {
  duration: number
  variants: {
    mobileInitial: Variant
    open: Variant
    close: TargetAndTransition
  }
}

export const MOBILE_INITIAL = 'mobileInitial'
export const SIDEBAR_ANIMATION_CONFIG: sidebarAnimationConfigType = {
  duration: 0.25,
  variants: {
    mobileInitial: { x: '-100%', visibility: 'hidden' },
    open: { x: 0, visibility: 'visible' },
    close: {
      x: '-100%',
      transitionEnd: {
        visibility: 'hidden',
      },
    },
  },
}
