import { forwardRef } from 'react'
import type { ChangeEventHandler } from 'react';
import clsx from 'clsx'
import styles from './Dropdown.module.scss'

type DropdownType = {
  name: string
  placeholder?: string
  options: {
    value: string
    label: string
  }[]
  onChange: ChangeEventHandler<HTMLSelectElement>
  onBlur?: () => void
  value?: string
  className?: string
}

type RefType = HTMLSelectElement

const Dropdown = forwardRef<RefType, DropdownType>(
  ({ name, placeholder, options, onChange, onBlur, value, className }: DropdownType, ref) => {
    return (
      <select
        id={name}
        className={clsx(styles.dropdown, className)}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        style={{ display: 'block' }}
      >
        {placeholder && (
          <option
            data-testid="placeholder"
            hidden
            disabled
          >
            {placeholder}
          </option>
        )}

        {options.map((option, index) => (
          <option
            key={`${index}-${option.value}`}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    )
  },
)

export default Dropdown
