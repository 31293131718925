import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateApiKey = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (apiKey: Partial<ApiKey>) => {
      const apiKeyBody = {
        status: apiKey.status,
      }

      const response = await fetch(`/api/v1/api_keys/${apiKey.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiKeyBody),
      })
      if (!response.ok) throw new Error('Failed to add api key')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['api_keys'] })
    },
  })
}
