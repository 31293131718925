import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

const fetchBusinesses = async () => {
  const response: AxiosResponse<Business[]> = await axios.get('/api/v1/businesses')

  return response.data
}
const useBusinesses = () => {
  const { data: businesses, isPending } = useQuery({
    queryKey: ['businesses'],
    queryFn: fetchBusinesses,
  })

  return {
    businesses,
    isLoading: isPending,
  }
}

export default useBusinesses
