import { Navigate, useLocation } from 'react-router-dom'
import { useSessionData } from 'hooks'
import { MAGIC_LOGIN_URL } from 'constants/routes'

const RequireAuthentication = ({ children }) => {
  const location = useLocation()
  const { isUserAuthenticated } = useSessionData()

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={MAGIC_LOGIN_URL}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

export default RequireAuthentication
