import { useMemo } from 'react'
import { useSelectedBusiness } from 'hooks'
import useStores from 'hooks/api/hub/useStores'
import useSessionData from 'hooks/useSessionData'

const useShouldShowCollectLinks = () => {
  const { session } = useSessionData()
  const { stores, isLoading: isLoadingStores } = useStores()
  const { selectedBusinessStoreIds } = useSelectedBusiness()

  const shouldAccessCollectLinks = useMemo(() => {
    if (isLoadingStores) {
      return false
    }

    const affiliatedStoreIds = session.affiliations
      ?.filter((affiliation) => affiliation.contactable_type === 'Store')
      .map((affiliation) => affiliation.contactable_id)

    const selectedBusinessStores = stores?.filter(
      (store) =>
        selectedBusinessStoreIds.includes(store.id) && affiliatedStoreIds.includes(store.id),
    )

    const hasCollectManagedStore = selectedBusinessStores?.some(
      (store) => store.managed === 'collect',
    )

    return hasCollectManagedStore
  }, [session, selectedBusinessStoreIds, stores])

  return { shouldAccessCollectLinks }
}

export default useShouldShowCollectLinks
