import { useSessionData } from 'hooks'
import { useBusinesses, useStores } from 'hooks/api/hub'

const useAffiliationInfo = () => {
  const { isUserAuthenticated, session } = useSessionData()
  const { businesses, isLoading: isLoadingBusinesses } = useBusinesses()
  const { stores, isLoading: isLoadingStores } = useStores()

  if (!isUserAuthenticated || isLoadingBusinesses || isLoadingStores) {
    return null
  }

  const businessAffiliations = session?.affiliations.filter(
    (a) => a.contactable_type === 'Business',
  )
  const storeAffiliations = session?.affiliations.filter((a) => a.contactable_type === 'Store')

  const affiliationInfo = {}
  if (businessAffiliations.length > 0) {
    affiliationInfo['business_name'] = ''
    affiliationInfo['business_id'] = ''
    businessAffiliations.forEach((affiliation, index) => {
      const business = businesses.find((b) => b.id === affiliation.contactable_id)
      if (!business) {
        return
      }

      affiliationInfo['business_name'] += business.name
      affiliationInfo['business_id'] += business.id

      if (businessAffiliations.length - 1 !== index) {
        affiliationInfo['business_name'] += ','
        affiliationInfo['business_id'] += ','
      }
    })
  }

  if (storeAffiliations.length > 0) {
    affiliationInfo['site_name'] = ''
    affiliationInfo['site_id'] = ''
    storeAffiliations.forEach((affiliation, index) => {
      const store = stores.find((s) => s.id === affiliation.contactable_id)
      affiliationInfo['site_name'] += store.name
      affiliationInfo['site_id'] += store.id

      if (storeAffiliations.length - 1 !== index) {
        affiliationInfo['site_name'] += ','
        affiliationInfo['site_id'] += ','
      }
    })
  }

  return affiliationInfo
}

export default useAffiliationInfo
