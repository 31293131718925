import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

const fetchApiKeys = async () => {
  const response: AxiosResponse<ApiKey[]> = await axios.get('/api/v1/api_keys')

  return response.data
}
const useApiKeys = () => {
  const { data: apiKeys, isPending, error } = useQuery({
    queryKey: ['api_keys'],
    queryFn: fetchApiKeys,
  })

  return {
    apiKeys,
    error,
    isLoading: isPending,
  }
}

export default useApiKeys
