import { StrictMode } from 'react'
import {
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MotionConfig } from 'framer-motion'
import { RequireAuthentication, RequireNoAuthentication } from 'services/user/access'
import {
  DASHBOARD_URL,
  EMAIL_REQUESTED_URL,
  IMPACT_DATA_URL,
  MAGIC_LOGIN_URL,
  STORE_POSTER_URL,
  COLLECTIONS_URL,
  API_KEYS_URL,
} from 'constants/routes'
import 'helpers/i18nextInit'
import CollectionsScene from 'scenes/hub/CollectionsScene'
import DashboardScene from 'scenes/hub/DashboardScene'
import ImpactDataScene from 'scenes/hub/ImpactDataScene'
import LoginEmailRequestedScene from 'scenes/hub/LoginEmailRequestedScene'
import MagicLoginScene from 'scenes/hub/MagicLoginScene'
import ErrorBoundary from 'components/ErrorBoundary'
import Layout from 'components/layout/Hub/Layout'
import StorePosterRoute from './StorePosterRoute'
import AuthenticatedLayout from './layout/Hub/AuthenticatedLayout'
import ApiKeysScene from 'scenes/hub/ApiKeysScene'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Sentry.ErrorBoundary fallback={<ErrorBoundary layout="hub" />}>
          <ScrollRestoration
            getKey={(location) => {
              return location.key
            }}
          />
          <Outlet />
        </Sentry.ErrorBoundary>
      }
    >
      <Route
        element={
          <RequireAuthentication>
            <AuthenticatedLayout />
          </RequireAuthentication>
        }
      >
        <Route
          path="/"
          element={<DashboardScene />}
        />
        <Route
          path={DASHBOARD_URL}
          element={<DashboardScene />}
        />
        <Route
          path={COLLECTIONS_URL}
          element={<CollectionsScene />}
        />
        <Route
          path={IMPACT_DATA_URL}
          element={<ImpactDataScene />}
        />
        <Route
          path={STORE_POSTER_URL}
          element={<StorePosterRoute />}
        />
        <Route
          path={API_KEYS_URL}
          element={<ApiKeysScene />}
        />
      </Route>

      <Route
        element={
          <RequireNoAuthentication>
            <Layout />
          </RequireNoAuthentication>
        }
      >
        <Route
          path={MAGIC_LOGIN_URL}
          element={<MagicLoginScene />}
        />
        <Route
          path={EMAIL_REQUESTED_URL}
          element={<LoginEmailRequestedScene />}
        />
      </Route>

      <Route element={<Layout />}>
        <Route
          path="*"
          element={<h1>Page not found</h1>}
        />
      </Route>
    </Route>,
  ),
)

const App = ({ session }: any) => {
  queryClient.setQueryData(['session'], session)
  queryClient.setQueryData(['selectedBusinessId'], session?.businesses[0]?.id)
  queryClient.setQueryData(['selectedBusinessStoreIds'], session?.businesses[0]?.store_ids)
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <MotionConfig reducedMotion="user">
          <RouterProvider router={router} />
        </MotionConfig>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </StrictMode>
  )
}

export default App
