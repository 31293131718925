import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdDinnerDining,
  MdOutlineArrowForward,
  MdOutlineShoppingBasket,
  MdGite,
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useSelectedBusiness } from 'hooks'
import { DateTime } from 'luxon'
import { IMPACT_DATA_URL } from 'constants/routes'
import Button from 'components/common/Button'
import LoadingSpinner from 'components/common/LoadingSpinner'
import ImpactDataCard from 'components/hub/ImpactData/ImpactDataCard'
import { useImpactData } from 'hooks/api/hub'
import styles from './Preview.module.scss'

const ImpactDataPreview = () => {
  const { t } = useTranslation()

  const yesterday = DateTime.now().minus({ day: 1 })
  const thirtyOneDaysAgo = DateTime.now().minus({ day: 31 })

  const { selectedBusinessStoreIds: storeIds } = useSelectedBusiness()
  const {
    data,
    fetchImpactData,
    isLoading: isLoadingImpactData,
  } = useImpactData({
    storeIds,
    startDate: thirtyOneDaysAgo.toFormat('yyyy-MM-dd'),
    endDate: yesterday.toFormat('yyyy-MM-dd'),
  })

  useEffect(() => {
    if (storeIds !== undefined && storeIds.length > 0) fetchImpactData()
  }, [storeIds])

  return (
    <div>
      <h2>{t('scenes.hub.impact-data.title')}</h2>
      <p className={styles.subtitle}>{t('components.impact-data-preview.subtitle')}</p>
      {isLoadingImpactData ? (
        <LoadingSpinner containerHeight="120px" />
      ) : (
        <div className={styles.grid}>
          <ImpactDataCard
            icon={<MdGite />}
            title={t('scenes.hub.impact-data.cards.households-fed.title')}
            value={data.households_fed.value}
            overlayContent={t('scenes.hub.impact-data.cards.households-fed.overlay')}
            overlayTitle={t('scenes.hub.impact-data.cards.households-fed.overlay-title')}
          />
          <ImpactDataCard
            icon={<MdDinnerDining />}
            title={t('scenes.hub.impact-data.cards.meals-shared.title')}
            value={data.meals_saved.value}
            overlayContent={t('scenes.hub.impact-data.cards.meals-shared.overlay')}
            overlayTitle={t('scenes.hub.impact-data.cards.meals-shared.overlay-title')}
            overlayLink={t('scenes.hub.impact-data.cards.meals-shared.overlay-link')}
            overlaySource={t('scenes.hub.impact-data.cards.meals-shared.source')}
          />
          <ImpactDataCard
            icon={<MdOutlineShoppingBasket />}
            title={t('scenes.hub.impact-data.cards.items-shared.title')}
            value={data.items_saved.value}
            overlayContent={t('scenes.hub.impact-data.cards.items-shared.overlay')}
            overlayTitle={t('scenes.hub.impact-data.cards.items-shared.overlay-title')}
          />
        </div>
      )}
      <Button
        type="button"
        rightContent={<MdOutlineArrowForward />}
        data-testid="view-impact-button"
      >
        <Link to={IMPACT_DATA_URL}>{t('components.impact-data-preview.button')}</Link>
      </Button>
    </div>
  )
}

export default ImpactDataPreview
