import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineCallReceived } from 'react-icons/md'
import { Link, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { MAGIC_LOGIN_URL } from 'constants/routes'
import { useGetReportAnIssueUrl } from 'helpers/typeform'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import OlioLogo from 'images/olio-colour-logo-square.svg?react'
import styles from './LoginEmailRequestedScene.module.scss'

const LoginEmailRequested = () => {
  const { t } = useTranslation()
  const typeformUrl = useGetReportAnIssueUrl({ referrer: 'partner-hub-email-requested' })

  const [searchParams] = useSearchParams()
  const userEmail = searchParams.get('email')

  return (
    <div className={styles.emailRequestedScene}>
      <h2 className={styles.title}>{t('scenes.hub.title')}</h2>
      <div className={clsx('card', styles.card)}>
        <div className="card-content">
          <div className={styles.logo}>
            <OlioLogo />
          </div>
          <h2 className={styles.title}>{t('components.hub.magic-login.email-requested.title')}</h2>
          <div className={styles.arrow}>
            <MdOutlineCallReceived className={styles.icon} />
          </div>
          <div className={styles.information}>
            <div>{t('components.hub.magic-login.email-requested.confirmation')}</div>
            <div className={styles.email} data-testid="user-email-text">{userEmail}</div>
            <div className={styles.emailNotReceived}>
              <SetInnerHtmlSafe>
                {t('components.hub.magic-login.email-requested.faqs.text', {
                  link: `<a href="${typeformUrl}">${t('components.hub.magic-login.email-requested.faqs.link-text')}</a>`,
                })}
              </SetInnerHtmlSafe>
            </div>
            <div className={styles.anotherAccount}>
              <Link to={MAGIC_LOGIN_URL}>
                {t('components.hub.magic-login.email-requested.another-account')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(LoginEmailRequested)
