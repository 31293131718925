import { Link, matchPath, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import styles from './SidebarLink.module.scss'

const SidebarLink = ({ icon, url, target = '_self', text, toggleSidebar }) => {
  const location = useLocation()
  const currentPath = location.pathname
  const matchingPath = matchPath({ path: url }, currentPath)
  const linkClass = matchingPath !== null ? 'active' : undefined

  return (
    <Link
      to={url}
      target={target}
      className={clsx(styles.sidebarLink, styles[linkClass])}
      onClick={() => toggleSidebar()}
    >
      <li>
        <span className={styles.iconAndTitle}>
          {icon}
          <span
            className={styles.link}
            data-testid="sidenav-link"
          >
            {text}
          </span>
        </span>
      </li>
    </Link>
  )
}

export default SidebarLink
