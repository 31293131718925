import { useQuery } from '@tanstack/react-query'
import useStores from 'hooks/api/hub/useStores'

const useCollectionsForBusiness = (businessId: number) => {
  const { stores = [], isLoading: isLoadingStores } = useStores()

  return useQuery({
    queryKey: ['collections', businessId],
    queryFn: () =>
      fetch(`/api/v1/collections?business_id=${businessId}`)
        .then((res) => res.json())
        .then((data) =>
          data.map((collection) => {
            const store = stores?.find((store) => store.id === collection.store_id)
            return {
              ...collection,
              location: store?.name || 'Unknown location',
            }
          }),
        ),
    enabled: !isLoadingStores,
  })
}

export default useCollectionsForBusiness
