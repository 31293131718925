import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectedBusiness } from 'hooks'
import { useStores } from 'hooks/api/hub'

const SidebarName = () => {
  const { t } = useTranslation()
  const { stores, isLoading: isLoadingStores } = useStores()
  const { selectedBusinessStoreIds } = useSelectedBusiness()
  const [isSingleStore, setIsSingleStore] = useState(false)

  const selectedStores = stores?.filter((store) => selectedBusinessStoreIds.includes(store.id))

  useEffect(() => {
    if (isLoadingStores === false) {
      setIsSingleStore(selectedStores?.length === 1)
    }
  }, [selectedStores, isLoadingStores])

  return (
    <>
      {isSingleStore && (
        <>
          <strong>{selectedStores[0].name}</strong>
          <strong>{t('components.common.at')}</strong>
        </>
      )}
    </>
  )
}

export default SidebarName
