import { useState, useEffect } from 'react'
import Button from 'components/common/Button'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Pill from 'components/common/Pill'
import Alert from 'components/common/Alert'
import SceneHeader from 'components/common/SceneHeader'
import AddApiKeyModal from 'components/hub/ApiKeys/AddApiKeyModal'
import { useApiKeys, useBusinesses } from 'hooks/api/hub'
import { useUpdateApiKey } from 'hooks/api/hub/useUpdateApiKey'
import { useTranslation } from 'react-i18next'
import styles from './ApiKeysScene.module.scss'

const ApiKeysScene = () => {
  const { t } = useTranslation()
  const { apiKeys = [], isLoading: isLoadingApiKeys, error } = useApiKeys()
  const { businesses, isLoading: isLoadingBusinesses } = useBusinesses()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAddMutationSuccess, setIsAddMutationSuccess] = useState(undefined)
  const [isUpdateMutationSuccess, setIsUpdateMutationSuccess] = useState(undefined)
  const { mutate: updateApiKey, isSuccess: isUpdateSuccess, isError: isUpdateError } = useUpdateApiKey()

  const revokeApiKey = (idToRevoke: number) => {
    const apiKey = apiKeys.find((apiKey) => apiKey.id === idToRevoke)
    updateApiKey({ ...apiKey, status: 'revoked' })
  }

  const setIsMutationSuccess = (result) => {
    setIsUpdateMutationSuccess(undefined)
    setIsAddMutationSuccess(result)
  }

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsAddMutationSuccess(undefined)
      setIsUpdateMutationSuccess(true)
    }
  }, [isUpdateSuccess])

  useEffect(() => {
    if (isUpdateError) {
      setIsAddMutationSuccess(undefined)
      setIsUpdateMutationSuccess(false)
    }
  }, [isUpdateError])

  return (
    <>
      <SceneHeader title={t('scenes.hub.api-keys.header')} />

      {isLoadingApiKeys || isLoadingBusinesses ? (
        <LoadingSpinner />
      ) : error ? (
        <div>{t('common.error')}</div>
      ) : (
        <>
          <Button
            onClick={() => {
              setIsModalOpen(true)
              setIsAddMutationSuccess(undefined)
              setIsUpdateMutationSuccess(undefined)
            }}
            className={styles.addButton}
            data-testid="add-api-key-button"
          >
            {t('scenes.hub.api-keys.add-new')}
          </Button>
          {isAddMutationSuccess && (
            <Alert className={styles.apiKeyAlert} variant="status">
              {t('scenes.hub.api-keys.success')}
            </Alert>
          )}
          {isAddMutationSuccess === false && (
            <Alert className={styles.apiKeyAlert} variant="error">
              {t('scenes.hub.api-keys.error')}
            </Alert>
          )}
          {isUpdateMutationSuccess && (
            <Alert className={styles.apiKeyAlert} variant="status">
              {t('scenes.hub.api-keys.success-revoke')}
            </Alert>
          )}
          {isUpdateMutationSuccess === false && (
            <Alert className={styles.apiKeyAlert} variant="error">
              {t('scenes.hub.api-keys.error-revoke')}
            </Alert>
          )}

          <AddApiKeyModal
          isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            businesses={businesses}
            setIsMutationSuccess={setIsMutationSuccess}
          />
          <div className={styles.apiKeyTableWrapper}>
            <table className={styles.apiKeyTable}>
              <thead>
                <tr>
                  <th>{t('scenes.hub.api-keys.business')}</th>
                  <th>{t('scenes.hub.api-keys.api-key')}</th>
                  <th>{t('scenes.hub.api-keys.date')}</th>
                  <th>{t('scenes.hub.api-keys.time')}</th>
                  <th>{t('scenes.hub.api-keys.status')}</th>
                  <th>{t('scenes.hub.api-keys.used-count')}</th>
                  <th>{t('scenes.hub.api-keys.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {apiKeys.map((apiKey, index) => {
                  const isLastRow = index === apiKeys.length - 1
                  const business = businesses.find((business) => business.id === apiKey.business_id)

                  if(!business) {
                    return null
                  }

                  return (
                    <tr key={index} className={isLastRow ? styles.lastRow : ''}>
                      <td>{business.name}</td>
                      <td>{apiKey.key}</td>
                      <td>{new Date(apiKey.created_at).toLocaleDateString()}</td>
                      <td>
                        {new Date(apiKey.created_at).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </td>
                      <td>
                        {apiKey.status === 'active' ? (
                          <Pill color="green" size="small" data-testid="active-key-pill">
                            <Pill.Text>{t('scenes.hub.api-keys.status-active')}</Pill.Text>
                          </Pill>
                        ) : (
                          <>
                            { apiKey.status === 'revoked' ? (
                              <Pill color="purple" size="small" data-testid="revoked-key-pill">
                                <Pill.Text>{t('scenes.hub.api-keys.status-revoked')}</Pill.Text>
                              </Pill>
                            ) : (
                              <Pill color="grey" size="small" data-testid="expired-key-pill">
                                <Pill.Text>{t('scenes.hub.api-keys.status-expired')}</Pill.Text>
                              </Pill>
                            )}
                          </>
                        )}
                      </td>
                      <td>{apiKey.used_count}</td>
                      <td>
                        {apiKey.status === 'active' && (
                          <Button
                            data-testid={`revoke-${apiKey.id}`}
                            variant="secondary"
                            onClick={() => {
                              if (window.confirm(t('scenes.hub.api-keys.revoke-confirm'))) {
                                setIsAddMutationSuccess(undefined)
                                setIsUpdateMutationSuccess(undefined)
                                revokeApiKey(apiKey.id)
                              }
                            }}
                          >
                            {t('scenes.hub.api-keys.revoke')}
                          </Button>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default ApiKeysScene
