import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdDescription,
  MdLibraryBooks,
  MdLogout,
  MdOutlineDashboard,
  MdQueryStats,
  MdCalendarMonth,
  MdLockOpen,
} from 'react-icons/md'
import clsx from 'clsx'
import { useSessionData } from 'hooks'
import {
  COLLECT,
  DASHBOARD,
  COLLECTIONS,
  IMPACT_DATA,
  STORE_POSTER,
  API_KEYS,
  getSidebarLinks,
} from 'constants/routes'
import Button from 'components/common/Button'
import { useLogout } from 'hooks/api/hub'
import useShouldShowCollectLinks from 'hooks/useShouldShowExternalLinks'
import useShouldShowPosterLink from 'hooks/useShouldShowPosterLink'
import CollectLogo from 'images/collect-icon.svg?react'
import SidebarInfo from '../SidebarInfo/SidebarInfo'
import SidebarLink from '../SidebarLink/SidebarLink'
import styles from './SidebarContent.module.scss'

const SidebarContent = ({ toggleSidebar }) => {
  const { t } = useTranslation()
  const logout = useLogout()
  const { shouldAccessStorePoster } = useShouldShowPosterLink()
  const { shouldAccessCollectLinks } = useShouldShowCollectLinks()
  const { isInternalUser } = useSessionData()

  const sidebarLinks = useMemo(() => {
    return getSidebarLinks(t)
  }, [])

  return (
    <div className={styles.contentContainer}>
      <div>
        <SidebarInfo />
        <SidebarLink
          icon={<MdOutlineDashboard className={styles.icon} />}
          url={sidebarLinks[DASHBOARD].url}
          text={sidebarLinks[DASHBOARD].text}
          toggleSidebar={toggleSidebar}
        />
        {isInternalUser && (
          <SidebarLink
            icon={<MdCalendarMonth className={styles.icon} />}
            url={sidebarLinks[COLLECTIONS].url}
            text={sidebarLinks[COLLECTIONS].text}
            toggleSidebar={toggleSidebar}
          />
        )}
        <SidebarLink
          icon={<MdQueryStats className={styles.icon} />}
          url={sidebarLinks[IMPACT_DATA].url}
          text={sidebarLinks[IMPACT_DATA].text}
          toggleSidebar={toggleSidebar}
        />
        {shouldAccessStorePoster && (
          <SidebarLink
            icon={<MdLibraryBooks className={styles.icon} />}
            url={sidebarLinks[STORE_POSTER].url}
            text={sidebarLinks[STORE_POSTER].text}
            toggleSidebar={toggleSidebar}
          />
        )}

        <SidebarLink
          icon={<MdLockOpen className={styles.icon} />}
          url={sidebarLinks[API_KEYS].url}
          text={sidebarLinks[API_KEYS].text}
          toggleSidebar={toggleSidebar}
        />

        {shouldAccessCollectLinks && (
          <>
            <strong className={clsx(styles.header, styles.upperCase)}>
              {t('layouts.sidebar.external-links')}
            </strong>
            <SidebarLink
              icon={<CollectLogo className={styles.icon} />}
              url={sidebarLinks[COLLECT].url}
              text={sidebarLinks[COLLECT].text}
              toggleSidebar={toggleSidebar}
            />
          </>
        )}

        <strong className={clsx(styles.header)}>{t('layouts.sidebar.coming-soon')}</strong>
        <div className={styles.inactiveLink}>
          <MdDescription className={styles.icon} />
          <span className={styles.link}>Documents</span>
        </div>
        <Button
          onClick={logout}
          variant="no-button"
          className={clsx(styles.logoutLink)}
        >
          <MdLogout className={styles.icon} />
          <span className={styles.link}>{t('phrases.logout')}</span>
        </Button>
      </div>
    </div>
  )
}

export default SidebarContent
