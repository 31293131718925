import { memo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useSelectedBusiness } from 'hooks'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import SceneHeader from 'components/common/SceneHeader'
import TextField from 'components/common/TextField'
import { usePosterData, useUpdatePosterData } from 'hooks/api/hub'
import styles from './StorePosterScene.module.scss'

const fields = {
  steps_one_title: 'collect.store_poster.steps.one.title',
  steps_one_body: 'collect.store_poster.steps.one.body',
  steps_two_title: 'collect.store_poster.steps.two.title',
  steps_two_body: 'collect.store_poster.steps.two.body',
  steps_three_title: 'collect.store_poster.steps.three.title',
  steps_three_body: 'collect.store_poster.steps.three.body',
  extra_info: 'collect.store_poster.steps.extra_info',
}

const StorePosterScene = () => {
  const { t } = useTranslation()
  const { selectedBusinessId: businessId } = useSelectedBusiness()
  const { data, isLoading } = usePosterData({ businessId })
  const [isPreview, setIsPreview] = useState(true)
  const { updatePosterData, error } = useUpdatePosterData()

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      steps_one_title: t(fields.steps_one_title),
      steps_one_body: t(fields.steps_one_body),
      steps_two_title: t(fields.steps_two_title),
      steps_two_body: t(fields.steps_two_body),
      steps_three_title: t(fields.steps_three_title),
      steps_three_body: t(fields.steps_three_body),
      extra_info: '',
    },
  })

  const { getValues, reset, handleSubmit } = methods

  const submit = async (mode?: 'reset'): Promise<void> => {
    const formValues: Record<string, string> = getValues()
    const updatedData = {}
    Object.keys(fields).forEach((key) => {
      updatedData[fields[key]] = mode === 'reset' ? t(fields[key]) : formValues[key]
    })
    if (mode === 'reset' || !formValues['extra_info']) {
      updatedData[fields.extra_info] = null
    }

    await updatePosterData({ businessId, updatedData })
    setIsPreview(true)
  }

  useEffect(() => {
    if (data) {
      const resetData = {}

      Object.keys(fields).forEach((key) => {
        resetData[key] = data?.[fields[key]] || t(fields[key])
      })
      resetData['extra_info'] = data?.[fields.extra_info] || ''
      reset(resetData)
    }
  }, [data])

  return (
    <>
      <SceneHeader
        title={t('scenes.hub.collect_poster.title')}
        description={t('scenes.hub.collect_poster.description')}
      />
      <FormProvider {...methods}>
        <Card className={styles.wrapper}>
          <form id="store-poster">
            <>
              <div>
                <h3>{t('collect.store_poster.steps.title')}</h3>
                <div className={clsx(styles.field, { [styles.isLoading]: isLoading })}>
                  <div>
                    {isPreview ? (
                      <>
                        <b>1. </b>
                        {isLoading === false &&
                          (data?.[fields.steps_one_title] || t(fields.steps_one_title))}
                      </>
                    ) : (
                      <TextField
                        labelText="1. "
                        name="steps_one_title"
                        rules={{ required: true, maxLength: 40 }}
                      />
                    )}
                  </div>
                  {isPreview ? (
                    isLoading === false &&
                    (data?.[fields.steps_one_body] || t(fields.steps_one_body))
                  ) : (
                    <TextField
                      name="steps_one_body"
                      rules={{ maxLength: 170 }}
                    />
                  )}
                </div>
                <div className={clsx(styles.field, { [styles.isLoading]: isLoading })}>
                  <div>
                    {isPreview ? (
                      <>
                        <b>2. </b>
                        {isLoading === false &&
                          (data?.[fields.steps_two_title] || t(fields.steps_two_title))}
                      </>
                    ) : (
                      <TextField
                        labelText="2. "
                        name="steps_two_title"
                        rules={{ required: true, maxLength: 40 }}
                      />
                    )}
                  </div>
                  {isPreview ? (
                    isLoading === false &&
                    (data?.[fields.steps_two_body] || t(fields.steps_two_body))
                  ) : (
                    <TextField
                      name="steps_two_body"
                      rules={{ maxLength: 170 }}
                    />
                  )}
                </div>
                <div className={clsx(styles.field, { [styles.isLoading]: isLoading })}>
                  <div>
                    {isPreview ? (
                      <>
                        <b>3. </b>
                        {isLoading === false &&
                          (data?.[fields.steps_three_title] || t(fields.steps_three_title))}
                      </>
                    ) : (
                      <TextField
                        labelText="3. "
                        name="steps_three_title"
                        rules={{ required: true, maxLength: 40 }}
                      />
                    )}
                  </div>
                  {isPreview ? (
                    isLoading === false &&
                    (data?.[fields.steps_three_body] || t(fields.steps_three_body))
                  ) : (
                    <TextField
                      name="steps_three_body"
                      rules={{ maxLength: 170 }}
                    />
                  )}
                </div>
              </div>
              <div>
                <h3>{t('scenes.hub.collect_poster.extra_info.title')}</h3>
                <div className={clsx(styles.field, { [styles.isLoading]: isLoading })}>
                  {isPreview ? (
                    isLoading === false &&
                    (data?.[fields.extra_info] || <i>Add any extra information here</i>)
                  ) : (
                    <TextField
                      name="extra_info"
                      placeholder={t('scenes.hub.collect_poster.extra_info.placeholder')}
                      rules={{ maxLength: 170 }}
                    />
                  )}
                </div>
              </div>
            </>

            {isPreview ? (
              <Button
                type="button"
                onClick={(event) => {
                  event.preventDefault()
                  setIsPreview(false)
                }}
                data-testid="edit-poster-button"
              >
                {t('scenes.hub.collect_poster.buttons.edit')}
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={handleSubmit(() => submit())}
                data-testid="save-poster-button"
              >
                {t('scenes.hub.collect_poster.buttons.save')}
              </Button>
            )}
            {error && <p className="error-message">{error}</p>}
          </form>
        </Card>
      </FormProvider>
      <Button
        type="button"
        onClick={() => submit('reset')}
        variant="secondary"
        data-testid="reset-poster-button"
      >
        {t('scenes.hub.collect_poster.buttons.reset')}
      </Button>
    </>
  )
}

export default memo(StorePosterScene)
