import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

const fetchStores = async () => {
  const response: AxiosResponse<Store[]> = await axios.get('/api/v1/stores')

  return response.data
}
const useStores = () => {
  const {
    data: stores,
    isPending,
    refetch,
  } = useQuery({
    queryKey: ['stores'],
    queryFn: fetchStores,
  })

  return {
    stores,
    isLoading: isPending,
    refetch,
  }
}

export default useStores
