import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { DASHBOARD_URL } from 'constants/routes'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import DataIcon from 'images/impact-data.svg'
import styles from './NoData.module.scss'

const NoData = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const { pathname } = location

  return (
    <Card>
      <div className={styles.noDataContent}>
        <DataIcon />
        <h2 className={styles.title}>{t('components.impact-data.no-data.title')}</h2>
        <span>{t('components.impact-data.no-data.description')}</span>
        {pathname !== DASHBOARD_URL && (
          <Button
            className={styles.button}
            variant="no-button"
            href={DASHBOARD_URL}
          >
            {t('components.impact-data.no-data.add-data.button')}
          </Button>
        )}
      </div>
    </Card>
  )
}

export default NoData
