import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const fetchImpactData = async ({ storeIds, startDate, endDate }) => {
  const response = await axios.post('/api/v1/store_statistics', {
    start_date: startDate,
    end_date: endDate,
    store_ids: storeIds,
  })

  return response.data
}

type useImpactDataType = {
  storeIds: number[]
  startDate: string
  endDate: string
}

const useImpactData = ({ storeIds, startDate, endDate }: useImpactDataType) => {
  const { data, isPending, refetch, dataUpdatedAt } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['impactData'],
    queryFn: () => fetchImpactData({ storeIds, startDate, endDate }),
    enabled: false,
    staleTime: Infinity,
  })

  return {
    data,
    fetchImpactData: refetch,
    isLoading: isPending,
    dataUpdatedAt,
  }
}

export default useImpactData
