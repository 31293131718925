import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useAddApiKey = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (newApiKey: Partial<ApiKey>) => {
      const newApiKeyBody = {
        business_id: newApiKey.business_id,
      }

      const response = await fetch('/api/v1/api_keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newApiKeyBody),
      })
      if (!response.ok) throw new Error('Failed to add api key')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['api_keys'] })
    },
  })
}
