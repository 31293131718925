import React from 'react'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import { DateTime } from 'luxon'
import { formatDateToDayOfWeekMonthAndTime } from 'helpers/time'
import styles from './TodaysDate.module.scss'

const TodaysDate: React.FC = () => {
  const todaysDate = formatDateToDayOfWeekMonthAndTime({ date: DateTime.now().toISO() })

  return (
    <div className={styles.iconAndDate}>
      <MdOutlineCalendarMonth className={styles.icon} />
      <span>{todaysDate}</span>
    </div>
  )
}

export default TodaysDate
