import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectedBusiness } from 'hooks'
import Dropdown from 'components/common/Dropdown'
import { useBusinesses } from 'hooks/api/hub'
import styles from './SidebarInfo.module.scss'
import SidebarStoreName from './SidebarStoreName'

const SidebarInfo = () => {
  const { t } = useTranslation()
  const { businesses, isLoading: isLoadingBusinesses } = useBusinesses()
  const { selectedBusinessId, setSelectedBusinessId } = useSelectedBusiness()
  const [displayBusinessName, setDisplayBusinessName] = useState('')
  const [logo, setLogo] = useState('')
  const [options, setOptions] = useState(null)

  useEffect(() => {
    if (isLoadingBusinesses === false && businesses.length > 1) {
      setOptions(
        businesses
          .map((business) => ({
            value: String(business.id),
            label: business.display_name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
    }
  }, [isLoadingBusinesses, businesses])

  useEffect(() => {
    if (isLoadingBusinesses === false) {
      const selectedBusiness = businesses.find((business) => business.id === selectedBusinessId)
      setDisplayBusinessName(selectedBusiness?.display_name)
      setLogo(selectedBusiness?.logo.url)
    }
  }, [isLoadingBusinesses, selectedBusinessId])

  const onSelectBusiness = (event) => {
    setSelectedBusinessId(Number(event.target.value))
  }

  if (isLoadingBusinesses) {
    return <div className={styles.sidebarInfoLoading} />
  }

  return (
    <div className={styles.sidebarInfo}>
      <div className={styles.businessAvatar}>
        <img
          alt={`${displayBusinessName} logo`}
          src={logo}
          className={styles.image}
        />
      </div>
      <div
        className={styles.details}
        data-testid="store-name"
      >
        <SidebarStoreName />
        <strong>{displayBusinessName}</strong>
      </div>
      {options && (
        <div className={styles.dropdown}>
          <label htmlFor="business-dropdown">{t('layouts.sidebar.info.select-label')}</label>
          <Dropdown
            name="business-dropdown"
            placeholder={t('layouts.sidebar.info.select-label')}
            value={String(selectedBusinessId)}
            options={options}
            onChange={onSelectBusiness}
          />
        </div>
      )}
    </div>
  )
}

export default SidebarInfo
