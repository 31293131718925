import { useEffect, useState, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import LoadingSpinner from 'components/common/LoadingSpinner'
import { useAddApiKey } from 'hooks/api/hub/useAddApiKey'
import styles from './AddApiKeyModal.module.scss'

const Select = lazy(() => import('components/common/Select'))

type AddApiKeyModalProps = {
  isOpen: boolean
  onClose: () => void
  businesses: Business[]
  setIsMutationSuccess: (isSuccess: boolean) => void
}

const AddApiKeyModal: React.FC<AddApiKeyModalProps> = ({
  isOpen,
  onClose,
  businesses,
  setIsMutationSuccess,
}) => {
  const { t } = useTranslation()
  const [newApiKey, setNewApiKey] = useState<Partial<ApiKey>>({
    business_id: undefined,
  })

  const businessOptions = businesses?.map((business) => ({
    value: String(business.id),
    label: business.name,
  }))

  const { mutate: addApiKey, isSuccess, isError } = useAddApiKey()

  const handleSubmit = () => {
    addApiKey(newApiKey)
    onClose()
  }

  useEffect(() => {
    if (isSuccess) {
      setIsMutationSuccess(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setIsMutationSuccess(false)
    }
  }, [isError])

  if (!isOpen) return null

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClose}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClose()
        }
      }}
      className={styles.modalOverlay}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.modalContent}
        onClick={(event) => event.stopPropagation()}
      >
        <h2>{t('components.hub.add-api-key-modal.add-new')}</h2>
        <div onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="business">{t('components.hub.add-api-key-modal.business')}</label>
            <Suspense fallback={<LoadingSpinner />}>
              <Select
                value={businessOptions?.find(
                  (option) => Number(option.value) === newApiKey.business_id,
                )}
                onChange={(selected) => {
                  setNewApiKey({
                    ...newApiKey,
                    business_id: selected?.value || undefined,
                  })
                }}
                options={businessOptions}
                name="business"
              />
            </Suspense>
          </div>
          <div className={styles.formActions}>
            <Button
              variant="secondary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              data-testid="add-key-modal-button"
            >
              {t('components.hub.add-api-key-modal.add-api-key')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddApiKeyModal
