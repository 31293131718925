import { Outlet } from 'react-router-dom'
import Header from '../Header'
import styles from './Layout.module.scss'

const Layout = () => {
  return (
    <>
      <Header />
      <main className={styles.wrapper}>
        <div className={styles.sceneContentWrapper}>
          <div className={styles.sceneContent}>
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}

export default Layout
