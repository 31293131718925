import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectedBusiness } from 'hooks'
import Mixpanel from 'services/analytics/Mixpanel'
import IssueReportForm from 'components/common/IssueReportForm'
import SceneHeader from 'components/common/SceneHeader'
import TodaysDate from 'components/common/TodaysDate'
import NoData from 'components/hub/ImpactData/NoData/NoData'
import ImpactDataPreview from 'components/hub/ImpactData/Preview/Preview'
import { Spacer } from 'components/layout/primitives'
import { useBusinesses } from 'hooks/api/hub'
import styles from './DashboardScene.module.scss'

const DashboardScene = () => {
  const { t } = useTranslation()
  const { businesses, isLoading } = useBusinesses()
  const { selectedBusinessStoreIds } = useSelectedBusiness()

  let businessId: number, displayName: string
  if (isLoading === false) {
    businessId = businesses[0]?.id
    displayName = businesses[0]?.display_name
  }

  useEffect(() => {
    Mixpanel.track('PARTNER_HUB_DASHBOARD_PAGE_LANDING')
  }, [])

  return (
    <>
      <TodaysDate />
      <SceneHeader
        title={t('scenes.dashboard.title')}
        description={t('scenes.dashboard.description')}
      />
      <Spacer height="2rem" />
      {selectedBusinessStoreIds?.length <= 0 ? <NoData /> : <ImpactDataPreview />}
      <section className={styles.dashboardSection}>
        <div className={styles.formWrapper}>
          <IssueReportForm
            className={styles.issueReportForm}
            headingText={t('partner-hub.issue-report-form.heading')}
            descriptionText={t('partner-hub.issue-report-form.description')}
            referrer="partner-hub-dashboard"
            businessId={businessId}
            businessName={displayName}
          />
        </div>
      </section>
    </>
  )
}

export default memo(DashboardScene)
